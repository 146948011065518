<template>
  <div class="attributes w-100 py-2 pb-3">
    <div class="row mr-2">
      <div class="col col-12 col-lg-3">
        <div class="row mb-1">
          <div class="col col-6 col-lg-5 pr-1">{{$t('motor.muj-nazev')}}:</div>
          <div class="col col-6 col-lg-7 bg-white text-break px-1">{{motor.name}}</div>
        </div>
        <div class="row mb-1">
          <div class="col col-6 col-lg-5 pr-1">{{$t('motor.vyrobce')}}:</div>
          <div class="col col-6 col-lg-7 bg-white text-break px-1">{{motor.manufacturer}}</div>
        </div>
        <div class="row mb-1">
          <div class="col col-6 col-lg-5 pr-1">{{$t('motor.rada')}}:</div>
          <div class="col col-6 col-lg-7 bg-white text-break px-1">{{motor.productline}}</div>
        </div>
        <div class="row mb-1">
          <div class="col col-6 col-lg-5 pr-1">{{$t('motor.osova-vyska')}}:</div>
          <div class="col col-6 col-lg-7 bg-white text-break px-1">{{Math.round(motor.axisheight)}} </div>
        </div>
      </div>

      <div class="col col-12 col-lg-3 pl-lg-4">

        <div class="row mb-1">
          <div class="col col-6 col-lg-7 pr-1">{{$t('motor.typ-kostry')}}:</div>
          <div class="col col-6 col-lg-5 bg-white text-break px-1">{{motor.typ}}</div>
        </div>
        <div class="row mb-1">
          <div class="col col-6 col-lg-7 pr-1">{{$t('motor.pocet-polu')}}:</div>
          <div class="col col-6 col-lg-5 bg-white text-break px-1">{{motor.pole}}</div>
        </div>

        <div class="row mb-1">
          <div class="col col-6 col-lg-7 pr-1">{{$t('motor.vykon-motoru')}}:</div>
          <div class="col col-6 col-lg-5 bg-white text-break px-1">{{motor.power}} kW</div>
        </div>

        <div class="row mb-1">
          <div class="col col-6 col-lg-7 pr-1">{{$t('motor.tvar-kostry')}}:</div>
          <div class="col col-6 col-lg-5 bg-white text-break px-1">{{motor.shape}}</div>
        </div>
      </div>

      <div class="col col-12 col-lg-3 pl-lg-4">




        <div class="row mb-1">
          <div class="col col-6 col-lg-6 pr-1">{{$t('motor.voltage')}}:</div>
          <div class="col col-6 col-lg-6 bg-white text-break px-1">{{motor.voltage}}</div>
        </div>





        <div class="row mb-1">
          <div class="col col-6 col-lg-6 pr-1">{{$t('motor.frequency')}}:</div>
          <div class="col col-6 col-lg-6 bg-white text-break px-1">{{motor.frequency}}</div>
        </div>

        <div class="row mb-1">
          <div class="col col-6 col-lg-6 pr-1">{{$t('motor.color')}}:</div>
          <div class="col col-6 col-lg-6 bg-white text-break px-1">{{motor.color}}</div>
        </div>

        <div class="row mb-1">
          <div class="col col-6 col-lg-6 pr-1">{{$t('motor.vyrobni-cislo')}}:</div>
          <div class="col col-6 col-lg-6 bg-white text-break px-1">{{motor.sn}}</div>
        </div>
      </div>

      <div class="col col-12 col-lg-3 pl-lg-4">


        <div class="row mb-1">
          <div class="col col-6 col-lg-6 pr-1">{{$t('m.CVSheet.LožiskaDE')}}:</div>
          <div class="col col-6 col-lg-6 bg-white text-break px-1">{{ motor.bearingDE }} {{ motor.bearingDEClearance }}  {{ motor.bearingDESealing }}</div>
        </div>

        <div class="row mb-1">
          <div class="col col-6 col-lg-6 pr-1">{{$t('m.CVSheet.LožiskaNDE')}}:</div>
          <div class="col col-6 col-lg-6 bg-white text-break px-1">{{ motor.bearingNDE }} {{ motor.bearingNDEClearance }}  {{ motor.bearingNDESealing }}</div>
        </div>


        <div class="row mb-1">
          <div class="col col-6 col-lg-6 pr-1">{{$t('m.CVSheet.Hmotnost')}}:</div>
          <div class="col col-6 col-lg-6 bg-white text-break px-1">{{ motor.weight }}</div>
        </div>

        <div class="row mb-1">
          <div class="col col-6 col-lg-6 pr-1">Companies ID</div>
          <div class="col col-6 col-lg-6 bg-white text-break px-1">{{ motor.companyID  ? motor.companyID : motor.userID}}</div>
        </div>










      </div>

      <!--

              Bearings NDE
              "bearingDE": null,
              "bearingDEClearance": null,
              "bearingDESealing": null,
              "bearingNDE": null,
              "bearingNDEClearance": null,
              "bearingNDESealing": null,
              "weight": null,

              <div class="col col-12 col-lg-5 pl-lg-5">

                <div class="row mb-1">
                  <div class="col col-6 col-lg-4 pr-1">{{$t('motor.poznamky')}}:</div>
                  <div
                    class="col col-6 col-lg-8 bg-white px-1"
                  >{{motor.note}}</div>
                </div>
              </div> -->

    </div>
  </div>
</template>

<script>
export default {
  name: "MotorAttributes",
  props:['motor']
}
</script>

<style scoped>

</style>
