<template>
  <div>
    <h1>{{$t('motor.deska.alarmy-link')}}</h1>

    <div class="alarmcontrols" v-if="motor">
      <img :src="tempImageUrl(motor)" />
      <img :src="vibrImageUrl(motor)" />
      <img :src="lubrImageUrl(motor)"  v-if="motor.type_need_lubrication"/>
      <img :src="maintanceImageUrl(motor)" />
      <img :src="measurementImageUrl(motor)" />
    </div>
    <alarm-history :motor="motor" v-if="motor"></alarm-history>
  </div>
</template>

<script>
import motorMixin from "@/views/motor/motorMixin";
import AlarmHistory from "@/views/motor/AlarmHistory";

export default {
  components: {AlarmHistory},
  mixins:[motorMixin],
  name: "Alarms",
  computed:{
    motor(){
      return this.$store.getters.motor
    }
  },
  data(){
    return {
      //motor:{activities:[]},
    }
  },
  mounted(){


  },
}
</script>

<style scoped lang="scss">
.alarmcontrols{
  img{
    width:90px;
    margin: 10px;
    background: white;
    padding:15px;
    border-radius: 45px;
  }
}
</style>
