<template>
  <div class="row">
    <div class="col-md-5">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>{{$t('motor.alerts.alerts-date')}}</th>
          <th>{{$t('motor.alerts.type')}}</th>
          <th>{{$t('motor.alerts.grade')}}</th>
          <th>{{$t('motor.alerts.limit')}}</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="a in alerts" :key="a.id">

          <td>{{a.date | dateTime}}</td>
          <td>{{a.kind}}</td>
          <td>{{a.value}}</td>
          <td>{{a.limit}}</td>
        </tr>

        </tbody>
      </table>

    </div>

    <div class="col-md-7">

        <table class="table table-striped">
          <thead>
          <tr>
            <th>{{$t('motor.alerts.date-of-repair')}}</th>
            <th>{{$t('motor.alerts.type')}}</th>
            <th>{{$t('motor.alerts.grade-after-service')}}</th>
            <th>{{$t('motor.alerts.limit')}}</th>
            <th>{{ $t('m.PDefect.PoznámkaTechnika')  }}</th>
            <th>{{ $t('motor.alerts.ststus')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="a in solvings" :key="a.id">

            <td>{{a.repair_date | dateTime}}</td>
            <td>{{a.kind}}</td>
            <td>{{a.sol_value}}</td>
            <td>{{a.limit}}</td>
            <td>{{a.note}}</td>
            <td><div :class="` d-flex p-2 ${statusClass(a)}`">&nbsp;</div></td>
          </tr>

          </tbody>
        </table>
    </div>

  </div>
</template>

<script>
import axiosApiInstance from "@/utils/api";
import moment from "moment";

export default {
  props:['motor'],
  name: "AlarmHistory",
  filters: {

    date(value) {
      value = moment(value).format('DD. MM. YYYY')
      return value
    },

    dateTime(value) {
      if (value==null) return ""
      value = moment(value).format('DD. MM. YYYY, H:mm')
      return value
    }
  },
  data(){
    return{
      alerts:[],
      solvings:[]
    }
  },
  mounted() {
    if (this.motor.id){
      this.loadData()
    }
  },
  methods:{
    statusClass(a){
      if (a.sol_value > a.limit) return 'badge-danger'
      return 'badge-success'

    },


    loadData(){

      axiosApiInstance.get(`/motor/${this.motor.token}/history`).then(resp =>{
        this.alerts = resp.data.alerts
        this.solvings = resp.data.solvings

      })

    }
  }
}
</script>

<style scoped>

</style>
