<template>
  <div>
    <div class="row">

      <div class="col-12 mt-3 mb-2" :key="keySelekted" v-if="selectedItemsCount>0">
        <strong class="num_selected">{{ selectedItemsCount}}</strong> {{$t('manufacture.selected')}}
        <button @click.prevent="batchDestroy" class="btn btn-outline-danger btn-sm"> <i class="fa fa-trash" aria-hidden="true"></i>  {{$t('manufacture.batch_destroy')}} </button>
      </div>


      <div class="col" style="">



        <table class="table table-bordered mt-2">
          <thead>
            <tr >
              <td>

                 <!--   <div class="dropdown">
                      <button class="btn btn-outline-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-bars"></i>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <a class="dropdown-item" href="#">Something else here</a>
                      </div>
                    </div>-->
              </td>
              <td>ID</td>
              <td>typ</td>
              <td>sn</td>
              <td>production_date</td>
              <td>power</td>
              <td>pole</td>
              <td>commision_date</td>
              <td>Alerts</td>
              <td></td>
            </tr>
            <tr >
              <td>

                <input type="checkbox" v-model="globalCb" />

            </td>
                <td></td>
                <td>
                  <select v-model="search_typ">
                    <option value="">all</option>
                    <option v-for="p in typ_options" :key="`typ${p}`" :value="p">{{p}}</option>
                  </select>
                </td>
                <td>
                  <div>text search <input type="text" v-model="search_sn"/></div>

                  <div class="d-flex mt-1">
                    <div class="mx-1">from</div><input type="text"  style="width: 100px;"  v-model="sn_from"/>
                    <div class="mx-1 ml-3">to</div><input type="text"  style="width: 100px;"  v-model="sn_to"/>
                  </div>
                </td>
                <td></td>
                <td><select v-model="search_power">
                  <option value="">all</option>
                  <option v-for="p in power_options" :key="`pow${p}`" :value="p">{{p}} kW</option>
                </select></td>
                <td>
                  <select v-model="search_pole">
                    <option value="">all</option>
                    <option>2</option>
                    <option>4</option>
                    <option>6</option>
                    <option>8</option>
                    <option>10</option>
                </select>

                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
          </thead>


          <template v-for="m in motors" >
            <tr :key="`k2${m.id}`" v-if="currentMotor.id == m.id " class="selected">
              <td colspan="9">
                <motor-attributes :motor="m"></motor-attributes>
              </td>
            </tr>
            <tr :key="`k1${m.id}`" @click="currentMotor = m " :class="`motorow cursor-pointer ${currentMotor == m ? 'selected': ''}`" >
              <td><input type="checkbox" @click.stop="cb_click"  v-model="selectedCb[m.id]" /></td>
              <td>{{m.motor_id}}</td>
              <td>{{m.typ}}</td>
              <td>{{m.sn}}</td>
              <td>{{m.production_date}}</td>
              <td>{{m.power}}</td>
              <td>{{m.pole}}</td>
              <td>{{m.commision_date}}</td>
              <td @click.prevent="openAlarms(m)"><div v-if="m.alert_level!=null" :class="'icon flex-fill align-self-center '+classBarvaKolecka(m.alert_level)"><i class="fas fa-circle state "></i></div></td>
              <td>{{m.deleted_at}}</td>
            </tr>

          </template>

        </table>

      </div>


      <div class="col-12 mt-3 mb-2" :key="keySelekted" v-if="selectedItemsCount>0">
        <strong class="num_selected">{{ selectedItemsCount}}</strong> {{$t('manufacture.selected')}}
        <button @click.prevent="batchDestroy" class="btn btn-outline-danger btn-sm"> <i class="fa fa-trash" aria-hidden="true"></i>  {{$t('manufacture.batch_destroy')}} </button>
      </div>

      <div class="col-md-12" v-if="err">
        {{err}}
      </div>

    </div>





    <modal name="modal1"
           :width="'90%'"
            :height="'auto'"

           :scrollable="true" >
      <div class="float-right">
        <button class='btn' @click="$modal.hide('modal1')">
          ❌
        </button>
      </div>
      <div class="p-3">
        <alarms></alarms>
      </div>
    </modal>



  </div>
</template>

<script>

import motorMixin from "@/views/motor/motorMixin";
import MotorAttributes from "@/views/motor/MotorAttributes";
import _ from "lodash";
import axios from "axios";
import userMixin from "@/mixins/userMixin";
import Vue from 'vue'
import vmodal from 'vue-js-modal'

Vue.use(vmodal)

import 'vue-js-modal/dist/styles.css'
import Alarms from "@/views/motor/Alarms";

export default {
  name: "Manufacture",
  components: {Alarms, MotorAttributes},
  mixins:[motorMixin, userMixin],
  data(){
    return{
      globalCb:false,
      selectedCb:{},
      currentMotor:{},
      motors:[],
      search_sn:'',
      search_power:null,
      search_pole:'',
      power_options:[100,200,1000],
      typ_options:[],
      err:null,
      keySelekted:111,
      selectedItemsCount:0,
      search_typ:'',
      sn_from:'',
      sn_to:''
    }
  },
  mounted() {

    if (this.$store.getters.me.company && this.$store.getters.me.company.status=='rejected' && this.$route.name!='nonconfirmed'){
      this.$router.push('/main/non-confirmed')
      return
    }
    this.loadMotors()
    this.loadOptions()
  },
  watch:{
    search_power: 'filterMotors',
    search_pole: 'filterMotors',
    search_sn: 'filterMotors',
    search_typ: 'filterMotors',
    sn_to: 'filterMotors',
    sn_from: 'filterMotors',
    globalCb(newVal){
      for(let i in this.motors){
        let m = this.motors[i]
        this.selectedCb[m.id]=newVal
      }

      this.selectedItemsCount_update()
    }
  },
  computed:{

  },

  methods:{
    selectedItemsCount_update(){
      let n=0
      for(let i in this.selectedCb){
        if (this.selectedCb[i]) n++;
      }
      this.selectedItemsCount=n
    },
    batchDestroy(){

      if (!confirm(this.$t('groups.remove-confirmation-cokoliv'))){
        return;
      }

      let ids=[]
      for(let i in this.selectedCb){
        if (this.selectedCb[i]) ids.push(i)
      }
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }
      const params = {
        manufacture_motors_ids: ids
      }
      axios.put(process.env.VUE_APP_API_ROOT_URL+'/manufacture/destroy?t='+ new Date().getTime(),  params, config).then(resp => {
        if (resp.status==200){
           this.loadMotors()
        }
      })
    },
    cb_click(){
      setTimeout(()=>{
        this.selectedItemsCount_update()
      }, 200)

    },

    toggleGlobalCb(){

    },

    reset_globalCb(){
      for(let i in this.selectedCb){
        this.selectedCb[i] = false
      }
      this.selectedItemsCount_update()
    },

    filterMotors:_.debounce(function () {
      this.loadMotors()
    }, 700),
    loadMotors(){
      const config = {
        params:{
          search_power: this.search_power,
          search_pole: this.search_pole,
          search_sn: this.search_sn,
          search_typ: this.search_typ,
          sn_from: this.sn_from,
          sn_to: this.sn_to
        }
        ,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }



      axios.get(process.env.VUE_APP_API_ROOT_URL+'/manufacture/index?t='+ new Date().getTime(), config).then(resp => {
        this.motors = this.expand_motorcocde(resp.data.motors)
        this.err=null
        this.reset_globalCb()
      }).catch(err =>{
        this.err=err.response.data.message
        this.motors = []


      })
    },


    loadMotor(id) {
      this.motorId = id

      console.log('load m', this.motorId)
      this.motor = {}
      axios.get(process.env.VUE_APP_API_ROOT_URL + "/motors/" + this.motorId + "/?t=" + new Date().getTime()).then(result => {
        //console.log("status motor",result.status,  result)
        if (result.status == 200) {

          this.motor = result.data
          this.$store.commit('setMotor', result.data)

          this.$modal.show('modal1')
        }
      })
    },

    loadOptions(){
      axios.get(process.env.VUE_APP_API_ROOT_URL+'/manufacture/filter_options').then(resp => {
        this.power_options = resp.data.power_options
        this.typ_options = resp.data.typ_options
      })
    },
    openAlarms(m){
      if (!m.token || m.token=="") return
      this.loadMotor(m.token)
      // this.$router.push(`/main/motors/${m.token}/alarms`)
    },
    expand_motorcocde(motors){

      for(var i=0; i< motors.length; i++){
        let m = motors[i]
        if (!m.motor_id){

          m.typ = m.code.substring(37, 40).replace(/_/g,'');
          m.power = parseInt(m.code.substring(31, 37).replace(/_/g,''))/100;
          m.pole = m.code.substring(29, 30).replace(/_/g,'');

          m.weight  = m.code.substring(75, 78).replace(/_/g,'');

          m.manufacturer = m.code.substring(2, 12).replace(/_/g,'');
          m.productline= m.code.substring(12, 22).replace(/_/g,'');
          m.axisheight= m.code.substring(22, 25).replace(/_/g,'');
          m.shape = m.code.substring(25, 28).replace(/_/g,'');// kostry
          m.voltage= m.code.substring(40, 43).replace(/_/g,'');
          m.frequency= m.code.substring(46, 48).replace(/_/g,'');
          m.color= m.code.substring(72, 76).replace(/_/g,'');

          m.bearingDE= m.code.substring(50, 55).replace(/_/g,'');
          m.bearingDEClearance= m.code.substring(55, 57).replace(/_/g,'');
          m.bearingDESealing= m.code.substring(57, 61).replace(/_/g,'');

          m.bearingNDE= m.code.substring(61, 66).replace(/_/g,'');
          m.bearingNDEClearance= m.code.substring(66, 68).replace(/_/g,'');
          m.bearingNDESealing= m.code.substring(68, 72).replace(/_/g,'');

          m.sn = m.code.substring(80, 90);
          m.companyID = m.code.substring(90, 100);
        }
        //console.log(m.code, m)
      }
      return motors
    }
  }

}
</script>

<style scoped lang="scss">
tr.cursor-pointer:hover{
  background-color:#fff;
}
tr.selected{
  background-color:#fab20a22;
  &.motorow td{
    font-weight: bold;
  }
}

.num_selected{
  font-size: 120%;
}
</style>
