
export default {
name: "motorMixin",
    computed:{

    },
    methods:{
        classBarvaKolecka(alert_level){


            if (alert_level>=2) return   "text-danger"
            if (alert_level==1) return   "text-warning"
            // if (this.motor.vStateColor == 'Red' || this.motor.tStateColor=='Red') return "text-danger"

            return "text-success"
        },




        vibrImageUrl(motor){
    //            const imgs= ['/img/VibrAlarmGray.png','/img/VibrAlarmGold.png','/img/VibrAlarmRed.png']
            if (motor && motor.states!=undefined){
            //const imgs= ['/img/TempAlarmGray.png', '/img/TempAlarmGold.png', '/img/TempAlarmRed.png']
                if (motor.states.vStateColor) {
                    let c= motor.states.vStateColor
                    if (c=='Red'  || c=='Gray' || c=='Gold'){
                        return `/img/VibrAlarm${c}.png`
                    }
                }
            }
            return '/img/VibrAlarmGray.png'
        },
        tempImageUrl(motor){
            if (motor && motor.states!=undefined){
            //const imgs= ['/img/TempAlarmGray.png', '/img/TempAlarmGold.png', '/img/TempAlarmRed.png']
                if (motor.states.tStateColor) {
                    let c= motor.states.tStateColor
                    if (c=='Red'  || c=='Gray' || c=='Gold'){
                        return `/img/TempAlarm${c}.png`
                    }
                }
            }
            return '/img/TempAlarmGray.png'
        },

        lubrImageUrl(motor){
            if (motor.notifications == undefined ) return ""

            for(var i in motor.notifications){
                let nf =  motor.notifications[i];
                if (nf.active && nf.message_code=='m.notice.need-lubrication'){
                    return "/img/LubrAlarmGold.png"
                }
            }
            return "/img/LubrAlarmGray.png"
        },

        maintanceImageUrl(motor){
            if (motor.notifications == undefined ) return ""

            for(var i in motor.notifications){
                let nf =  motor.notifications[i];
                if (nf.active && nf.message_code=='m.notice.need-maintance'){
                    return "/img/ServiceAlarmGold.png"
                }
            }
            return "/img/ServiceAlarmGray.png"
        },


        measurementImageUrl(motor){
            if (motor.notifications == undefined ) return ""

            for(var i in motor.notifications){
                let nf =  motor.notifications[i];
                if (nf.active && nf.message_code=='m.notice.need-measurement'){
                    return "/img/MeasAlarmGold.png"
                }
            }
            return "/img/MeasAlarmGray.png"
        }

    }
}
